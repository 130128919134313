import React, { useCallback, useEffect, useState } from 'react'
import eth from "../../../assets/images/ethlogo.svg"
// import share from "../../../assets/images/share.svg"
import { toast } from 'react-toastify';
import { get } from "../../../utils/api/apiHelper";
import { fullDateFormat, showSlicedText } from '../../../utils/common';
import PaginationComponent from '../../Pagination'
import { useNavigate ,Link} from 'react-router-dom';
import { useActiveAccount } from 'thirdweb/react';

const BattleEntered = () => {
    const navigate = useNavigate();
    const activeAccount = useActiveAccount();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const getWallet_History = useCallback(async () => {
        try {
            setIsFetching(true)
            let response = await get(`/client/battle_history?pageNumber=${currentPage}&pageSize=${perPage}`, localStorage.getItem('accessToken'));
            if (response.status === 200) {
                console.log(response.data.items, 'response.data.items');
                setItems(response.data.items);
                setTotal(response.data?.totalItems ?? 0);
                setPerPage(response.data.pageSize);
                setIsFetching(false)
            }
        } catch (error) {
            setIsFetching(false)
            toast.error('Something went wrong!', {
                toastId: "error"
            });
        }
    }, [currentPage, perPage]);
    useEffect(() => {
        getWallet_History();
    }, [getWallet_History]);

    return (
        <div>
            <div className="container ">
                <div className="battle-enter mt-3 border-bottom-4">
                    {
                        total > 0 &&
                        <div className="row mx-0 py-4">
                            <div className=" col d-none d-md-block">
                                <p className="text-gray mb-0">Prize Pool</p>
                            </div>
                            <div className=" col d-none d-md-block">
                                <p className="text-gray mb-0">Amount</p>
                            </div>
                            <div className=" col text-center d-none d-md-block">
                                <p className="text-gray mb-0">Your Fighters</p>
                            </div>
                            <div className="col text-center d-none d-md-block">
                                <p className="text-gray mb-0">Total fighters</p>
                            </div>
                            {/* <div className="col text-center d-none d-md-block">
                                <p className="text-gray mb-0">battle hash</p>
                            </div> */}
                            <div className="col text-end d-none d-md-block">
                                <p className="text-gray mb-0">Time</p>
                            </div>
                            
                            <div className="col text-end d-none d-md-block">
                                <p className="text-gray mb-0">Action</p>
                            </div>
                        </div>
                    }
                    <div>
                        {
                            total <= 0 &&
                            <p className="text-warning text-center fs-5">
                                {
                                    isFetching ? "Fetching !" : "Sorry you have not entered in any battle !"
                                }
                            </p>
                        }
                        {
                            total > 0 && items?.map((data, i) => {
                                return (
                                    <div className={`${(data?.isActive) ? 'bg-light' : 'bg-gadient'} battle-enter-area d-inline-block   w-100 rounded-4 mb-3 clearfix`} key={data?._id}>
                                        <div className="bg-dark-1 rounded-4">
                                            <div className="row mx-0 align-items-center pt-4 py-1 ">
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Prize Pool</p>
                                                </div>
                                                <div className="col-6 col-md-2 text-md-start text-end">
                                                    <div className="battle-enter-content mb-3 mb-md-0">
                                                        <p className="text-cyan">
                                                            <img alt='' src={eth} /> <span>{data?.prize_pool}</span> eth
                                                            <br />
                                                            {
                                                                data?.isCompleted && <>
                                                                    {
                                                                        data?.isResultDeclared
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    `${data?.winner}`.toLowerCase() === activeAccount?.address?.toLowerCase() ?
                                                                                        <span className='text-success'>WON</span> : <span className='text-danger'>LOST</span>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <span className='text-secondary'>NOT DECLARED</span>
                                                                    }
                                                                </>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Amount</p>
                                                </div>
                                                <div className=" col-6 col-md-2 text-md-start text-end">
                                                    <div className="battle-enter-content mb-3 mb-md-0">
                                                        <p className="text-cyan"><img alt='' src={eth} /> {data?.amount_freezed} eth</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 d-md-none mb- mb-md-0">
                                                    <p className="text-gray mb-0">Your fighters</p>
                                                </div>
                                                <div className="col-6 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                    <p className="text-white">{data?.you_bought}</p>
                                                </div>
                                                <div className="col-6 d-md-none mb-1 mb-md-0">
                                                    <p className="text-gray mb-0">Total fighters</p>
                                                </div>

                                                <div className="col-6 col-md-2 text-end text-md-center mb-1 mb-md-0">
                                                    <p className="text-gray">{data?.total_tickets_sold}</p>
                                                </div>
                                                {/* <div className="col-6 d-md-none mb-1 mb-md-0">
                                                    <p className="text-gray mb-0">battle hash</p>
                                                </div>
                                                <div className="col-md-2 col-6 text-end text-md-center mb-1 mb-md-0">
                                                    {
                                                        data?.isCompleted ?
                                                            <Link to={`/battleEnded/${data?._id}`}><p className="text-cyan d-inline-block text-break text-truncate m-0" style={{ width: '100%' }}>{showSlicedText(data?.contractAddress)}</p></Link>
                                                            :
                                                            <p className="text-cyan d-inline-block text-break text-truncate m-0" style={{ width: '100%' }}>{showSlicedText(data?.contractAddress)}</p>
                                                    }
                                                </div> */}
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Time</p>
                                                </div>
                                                <div className="col-6 col-md-2 text-end pt-1 pt-md-0 mb-3">
                                                    {
                                                        // data?.isActive ?
                                                        //     <Link to={`/battle/${data?._id}`} className="btn-default buy-btn"><span className="text-gradient">buy more</span></Link>
                                                        //     :
                                                        <p className="text-gray">{fullDateFormat(data?.updatedAt)}</p>
                                                    }
                                                    {/* <p className="text-gray">15.11.2022, 10:32:15 <img alt='' src={share} /></p> */}
                                                </div>

                                                <div className="col-md-2 text-md-end text-center pt-2 pt-md-0 mb-3">
                                                    {
                                                        data?.isActive ?
                                                            <button onClick={() => {
                                                                navigate(`/battle/${data?._id}`);
                                                            }} className="btn-default buy-btn"><span className="text-gradient">buy more</span></button>
                                                            :
                                                            <button onClick={() => {
                                                                navigate(`/battleEnded/${data?._id}`);
                                                            }} className="btn-default buy-btn"><span className="text-gradient">View</span></button>

                                                            // <p className="text-gray">{fullDateFormat(data?.updatedAt)}</p>
                                                            // <Link to={`/battleEnded/${data?._id}`}><p className="text-cyan d-inline-block text-break text-truncate m-0" style={{ width: '100%' }}>{"View Battle"}</p></Link>
                                                    }
                                                    {/* <p className="text-gray">15.11.2022, 10:32:15 <img alt='' src={share} /></p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        <div className="pb-4 mt-4 ">
                            {total > 0 && (
                                <PaginationComponent
                                    className="mx-auto"
                                    itemsCount={total}
                                    itemsPerPage={perPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BattleEntered